// Modules exported here will be included in bundle.js

import { createFocusTrap } from "focus-trap";
/** adopted from @module @yext/components-util */

/**
 * Helper class for accessibility operations
 */
export class AccessibilityHelpers {
  /**
   * Set the value of an aria property on an element
   * @param {HTMLElement} element
   * @param {string} ariaProp Property name without "aria-", e.g. "hidden" for "aria-hidden"
   * @param {string} ariaValue Property value
   */
  setAriaProp(element, ariaProp, ariaValue) {
    element.setAttribute(`aria-${ariaProp}`, ariaValue);
  }

  /**
   * Toggle an aria property value between "true" and "false"
   * @param {HTMLElement} element
   * @param {string} ariaProp Property name without "aria-", e.g. "hidden" for "aria-hidden"
   */
  toggleAriaState(element, ariaProp) {
    if (!element.hasAttribute(`aria-${ariaProp}`)) return;
    const currAriaValue = element.getAttribute(`aria-${ariaProp}`);
    const newAriaValue = !(currAriaValue == 'true');
    element.setAttribute(`aria-${ariaProp}`, newAriaValue);
  }

  /**
   * @param {HTMLElement|NodeList|string} target Target element(s) or selector
   * @param {number} tabIndex
   */
  setTabIndex(target, tabIndex) {
    let els = [];
    if (typeof(target) === 'string') {
      els = document.querySelectorAll(`${selector}`);
    } else if (target instanceof HTMLElement) {
      els = [target];
    } else if (target instanceof NodeList) {
      els = target;
    }

    for (const el of els) {
      el.tabIndex = tabIndex;
    }
  }
}

// export const createFocusTrap
export class FocusTrap {
    static createFocusTrap(){
        return createFocusTrap;
    }
}